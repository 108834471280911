































import moment from "moment";
import Vue from "vue";

export default Vue.extend({
  name: "DashboardCoreFooter",

  data: () => ({
    year: moment().year(),
    links: [],
  }),
});
